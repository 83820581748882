export const overviewPageData = {
  aidSustainablityText: "We aim to create a visible and direct social impact",
  // "In our modern times an organization would greatly benefit if it can have such a visible and direct social impact which Aradina passes with flying colors",
  ourVision:
    "To blend tradition with technology, connecting age-old agricultural wisdom with modern innovation.",
  ourMission:
    "To revive local lands, support farmers and communities, and reap rewards.",
  // "Lorem ipsum dolor sit amet consectetur. Quisque malesuada eget tellus turpis sed aliquam.",
  // ourMantra:
  //   "Lorem ipsum dolor sit amet consectetur. Quisque malesuada eget tellus turpis sed aliquam.",
  agtechCompany: [
    "Tangible, trackable, and secure assets.",
    "Access to a growing industry with significant potential.",
    "Budget-friendly solutions to revitalize lands.",
    "Economic growth through agriculture.",
    "Professional support for farmers to maintain fertile lands.",
    // "Aradina will plan the experience of the customer - Understand their channels, their behavior across all channels, and create a detailed plan on how they want the experience to flow across all the touchpoints.",
    // "Use data as a base while strategizing - With the help of CRM data, social listening data, and customer's online search behavior, we will know how our customer behaves and can accordingly create a solution to address their challenges. We can even reduce the churn by using the data in the right way.",
    // "Segment the users and personalize the journey. Once we analyze the data, we can easily segment users into different categories based on common behavior patterns. This will help us to create personalized journeys for each type of customer.",
  ],
  aradinaEmpowers: [
    "Creates a sense of belonging",
    "Builds hope",
    "Provides opportunities",
    "Empowers communities",
    "Sustains the environment",
    // "Societies",
    // "Rural communities",
    // "Creating better lives",
    // "Sustaining the environment",
    // "Providing opportunities",
    // "Building hope",
    // "Achieving bonds",
    // "To be practical enabling return on investment for both farmers, investors and all other stakeholders",
  ],
  cards: [
    {
      title: "Expertise",
      content: [
        "Our offerings are carefully vetted by industry experts.",
        "Comprehensive due diligence on every asset.",
        "Farmers get professional advice and business plans.",
        // "Our offerings are carefully qualified by experts in agricuture.",
        // "Expansive and comprehensive due diligence on each investment opportunity to qualify for listing.",
        // "Experienced farmers with networks to producers and distributors.",
      ],
    },
    {
      title: "Transparency",
      content: [
        "Open communication and full disclosure.",
        "No hidden terms.",
        "Real-time access to dashboards tailored for each client.",
        // "Open communication, complete disclosure.",
        // "No fone print or hidden terms.",
        // "Advanced enabling technologies that allow for real time access to dashboard tailored for each investor.",
      ],
    },
    {
      title: "Returns",
      content: [
        "Guaranteed profits with risks mitigated by diligence and insurance.",
        "Use of advanced calculators and clear exit strategies.",
        // "Almost guaranteed returns.",
        // "Risks are mitigated by diligence and insurance.",
        // "Adavnced calculators and clear exit startegies.",
      ],
    },
  ],
  investmentFramwork:
    "Designing a framework that helps the organizational units (i.e., its departments, divisions, and standing committees) to follow up on measuring its strategic and procedural goals, by relying on integrated performance standards and indicators, to benefit from its readings in activating administrative, technical and financial decision-making.",
  about: [
    "Harvesting the abundance of mother earth and its grazing inhabitants of its vast farms.",
    "Rooting people in their lands and preventing adverse circumstances from forcing them to be uprooted.",
    "Love of country, nature, people, and growing in healthy abundant societies.",
  ],
};

export default overviewPageData;
